import "./Products.css";

import ProductsList from "./ProductsList/ProductsList";

import { products } from "../../Data/products";

const Products = () => {
  return (
    <section className="products container">
      <h6 className="transition-from-bottom">Services You Can Rely On</h6>
      <h1 className="transition-from-left">What We Offer</h1>
      <ProductsList 
        animation={"transition-from-top"}
        products={products} 
      />
    </section>
  );
}
 
export default Products;