import "./ProductsList.css";

import ProductCard from "../../../General/ProductCard/ProductCard";

import { products } from "../../../Data/products"


const ProductsList = ({ animation = "" }) => {
  return (
    <section className={`products-list ${animation}`}>
      {
        products.
        length?
        products.map((product, index) => (
          <div key={index}>
            <ProductCard 
              key={index}
              image={product.image}
              name={product.name}
              description={product.shortDescription}
            />
          </div>
      ))
      :
      <h4 style={{textAlign: "center"}}>No Service Found!</h4>
      }
    </section>
  );
}
 
export default ProductsList;