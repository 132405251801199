import "./Footer.css";

import heroBg from "../../Images/Home/Hero/hero-bg.svg";
import locationIcon from "../../Images/General/location-icon.svg";
import phoneIcon from "../../Images/General/phone-icon.svg";
import emailIcon from "../../Images/General/email-icon.svg";
import logo from "../../Images/General/logo-blue-bg.jpg";

import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer>
      <div className="background">
        <img src={heroBg} alt="Hero-Bg" />
        <div className="container">
          <div className="left-section">
            <div className="logo">
              <img src={logo} alt="Fire-Flick-Logo" />
            </div>
            <p>Committed to safety, innovation, and excellence.</p>
          </div>
          <div className="right-section">
            <div className="top-section">
              <div className="col-2">
                <h4>
                  <img 
                    src={locationIcon} alt="location-icon"
                    className="icon"  
                  />
                  <Link 
                    style={{fontSize: "inherit", pointerEvents: "none"}} 
                  >
                    Lahore (Head Office)
                  </Link>
                </h4>
                <p>Main Boulevard, DHA Phase 9 Town,</p>
                <p>Workshop Stop, Bedian Road.</p>
              </div>
              <div className="col-3">
                <h4>
                  <img 
                    src={locationIcon} alt="location-icon"
                    className="icon"  
                  />
                  <Link 
                    style={{fontSize: "inherit", pointerEvents: "none"}} 
                  >
                    Islamabad
                  </Link>
                </h4>
                <p>House No: 209, Street 6B,</p>
                <p>Ghouri Town VIP Block Khanna Dak.</p>
              </div>
              <div className="col-4">
                <h4>
                  <img 
                    src={locationIcon} alt="location-icon"
                    className="icon"  
                  />
                  <Link 
                    style={{fontSize: "inherit", pointerEvents: "none"}} 
                  >
                    KPK
                  </Link>
                </h4>
                <p>SF 869 DEANS Trade Center Peshawar</p>
              </div>
            </div>
            <div className="bottom-section">
              <Link to="mailto:info@fireflicksolutions.com">
                <img 
                  src={emailIcon} 
                  alt="Email-Icon" 
                  className="icon-alternate"
                />
                info@fireflicksolutions.com
              </Link>
              <p>
                <img 
                  src={phoneIcon} 
                  alt="Phone-Icon" 
                  className="icon-alternate"
                />
                <Link to="tel:+923454877525">
                  0345-4877525
                </Link>
                &nbsp;/&nbsp;
                <Link to="tel:+923074875525">
                  0307-4875525
                </Link> 
              </p>
            </div>
          </div>
        </div>
        <div className="copyright">
          <h5>Copyright © {new Date().getFullYear()} Fire Flick Solutions. All Rights Reserved.</h5>
        </div>
      </div>
    </footer>
  );
}
 
export default Footer;