import "./Hero.css";

import heroBg from "../../Images/Home/Hero/hero-bg.svg";
import heroImg1 from "../../Images/Home/Hero/hero-img-1.jpg";
import heroImg2 from "../../Images/Home/Hero/hero-img-2.jpg";

import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <section className="hero">
      <div className="background">
        <img src={heroBg} alt="Hero-Bg" />
        <div className="container">
          <div className="left-section">
            <h6 className="transition-from-top">Advanced Firefighting Solutions</h6>
            <h1 className="transition-from-top">Top-Quality Firefighting Equipment</h1>
            <p className="transition-from-top">Discover our extensive range of top-quality firefighting equipment designed to keep you safe and efficient in critical situations. From durable fire suits to advanced firefighting tools, we have everything you need to handle any emergency with confidence.</p>
            <Link to="/">
              <button className="transition-from-top">Contact Us</button>
            </Link>
          </div>
          <div className="right-section">
            <img src={heroImg1} className="transition-from-right" alt="Hero-Img-1" />
            <img src={heroImg2} className="transition-from-right" alt="Hero-Img-2" />
          </div>
        </div>
      </div>
    </section>
  );
}
 
export default Hero;