import GeneralHero from "../General/GeneralHero/GeneralHero";
import Products from "./Products/Products";

const Services = () => {

  return (
    <main style={{paddingTop: "8rem"}}>
      <GeneralHero title={"Services"} description={"Delivering reliable fire safety services that protect lives and property."} />
      <Products />
    </main>
  );
}
 
export default Services;