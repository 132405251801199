import "./header.css";

import logo from "../../Images/General/logo-blue-bg.jpg";

import { Link } from "react-router-dom";

const Header = () => {
  return (
    <header>
      <div className="container">
        <div className="left-section">
          <Link to="/">
            <img src={logo} alt="Fire-Flick-Logo" />
          </Link>
        </div>
        <div className="right-section">
          <div className="lg">
            <Link to="/">Home</Link>
            <Link to="/about-us">About Us</Link>
            <Link to="/services">Services</Link>
          </div>
        </div>
      </div>
    </header>
  );
}
 
export default Header;